import 'alpinejs';
import 'lightgallery.js';
import focusTrap from 'focus-trap';


/**
 * 
 */
window.initTrap = function(el) {
    return focusTrap(el);
}

/**
 * 
 */
window.updateFocusTrap = function (trap, el, state) {
    if(state == true) {
        setTimeout(function() {
            trap.activate();
            el.querySelector('input').focus();
        }, 100);
    } else {
        trap.deactivate();
    }
}




/**
 * 
 */
const lgContainers = document.querySelectorAll('[data-lightgallery]');
if (lgContainers) {
    lgContainers.forEach(el => {
        lightGallery(el, {
            selector: 'img[data-gallery]',
            download: false,
        });

        let triggers = el.querySelectorAll('[data-gallery-trigger]');
        let firstImage = el.querySelector('img[data-gallery]');

        if (triggers && firstImage) {
            triggers.forEach(triggerEl => {
                triggerEl.addEventListener('click', function () {
                    firstImage.click();
                });
            });
        }
    })
}



/**
 * 
 */
window.slider = function (slideCount) {
    return {
        slideCount: slideCount,
        prev(container, slide) {
            container.scrollLeft -= slide.clientWidth;
        },
    
        next(container, slide) {
            container.scrollLeft += slide.clientWidth;
        }
    };
}


/**
 * 
 */
window.searchForm = function () {
    return {
        token: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),

        term: '',
        options: [],
        threshold: 2,
        enteredCharLength: false,


        isBoxOpen: false,
        isLoading: false,
        isOptionsOpen: false,

        init(term) {
            let params = new URLSearchParams(location.search);
            if(term) {
                this.term = term;
            } else {
                this.term = params.get('q') || '';
            }
        },

        fetchKeywords(type = 'sale') {
            this.isLoading = true;
            let self = this;

            let formData = new FormData();
            formData.append('type', type);
            formData.append('token', this.token);


            fetch('/api/properties/keywords', {
                method: 'POST',
                body: formData
            })
                .then(RES => RES.json())
                .then(function (data) {
                    self.options = data.sets.keywords;
                    self.isLoading = false;
                })
                .catch((error) => {
                    self.isLoading = false;
                })
        },



        filteredOptions() {
            if (this.term.length < this.threshold) return [];

            return this.options.filter((option) => {
                return option
                    .toLowerCase()
                    .includes(this.term.toLowerCase());
            });
        },


        focusToOptions(dropdown) {
            if (this.filteredOptions().length) {
                dropdown.querySelector('.dropdown-item').focus();
            }
        },


        focusDropdownDown() {
            let el = document.activeElement.nextElementSibling;
            if (el) el.focus();
        },

        focusDropdownUp(input) {
            let el = document.activeElement.previousElementSibling;

            if (el.classList.contains('dropdown-item')) {
                el.focus();
            } else {
                input.focus()
            }
        },


        handleMinOptsChange(minInput, maxInput) {
            if (parseInt(minInput.value) >= parseInt(maxInput.value || 0)) {
                if (maxInput.selectedIndex < maxInput.options.length - 1) {
                    maxInput.selectedIndex = maxInput.selectedIndex + 1;
                    this.handleMinOptsChange(minInput, maxInput);
                }
            }
        },

        handleMaxOptsChange(minInput, maxInput) {
            if (parseInt(minInput.value) >= parseInt(maxInput.value || 0)) {
                if (minInput.selectedIndex > 0) {
                    minInput.selectedIndex = minInput.selectedIndex - 1;
                    this.handleMaxOptsChange(minInput, maxInput);
                }
            }
        }
    }
}





/**
 * 
 * @param {*} locationName 
 * @param {*} lat 
 * @param {*} lng 
 */
window.gMap = function (address, lat, lng) {
    return {
        address: address,
        lat: lat,
        lng: lng,

        render(el) {
            let coords;
            let opts = {
                zoom: 15,
                scrollwheel: false,
                gestureHandling: 'cooperative',
                disableDefaultUI: true,
            };


            if (this.lat && this.lng) {
                coords =  { lat: parseFloat(lat), lng: parseFloat(lng) };
                opts.center = coords;

                let map = new google.maps.Map(el, opts);
                new google.maps.Marker({ position: coords, map: map });

            } else {
                let geocoder = new google.maps.Geocoder();

                geocoder.geocode( {'address': `${this.address}, United Arab Emirates`}, function(results, status) {
                    if (status == 'OK') {
                        coords = results[0].geometry.location;
                        opts.center = coords;
                        let map = new google.maps.Map(el, opts);
                        new google.maps.Marker({ position: coords, map: map });
                    }
                });
            }


        }
    }
}



/**
 * 
 */
const numberInputs = document.querySelectorAll('[data-numbers]');
if(numberInputs) {
    numberInputs.forEach(el => {
        el.addEventListener('input', function() {
            el.value = el.value.replace(/[^0-9]/g, "");
        })
    });
}





/**
 * 
 */
$readMoreJS.init({
    target: '[data-readmore]',
    numOfWords: 50,
    toggle: true,
    moreLink: 'read more +',
    lessLink: 'read less -',
    linkClass: 'button is-light has-text-weight-bold',
    containerClass: 'readmore-container'
});





/**
 * 
 */
var fileInputContainer = document.querySelector('[data-file-input]');
if(fileInputContainer) {
	let fileError = document.querySelector('[data-file-input-error]');
	var fileInput = document.querySelector('[data-file-input] input[type=file]');
	
	fileInput.addEventListener('change', function() {
		if (fileInput.files.length > 0) {
			// The size of the file. 
			let fileSize = fileInput.files[0].size; 
			let file = Math.round((fileSize / 1024));
			let fileName = document.querySelector('[data-file-input] .file-name');
	
			if (file >= 1025) { 
				fileInputContainer.classList.remove('is-success')
				fileInputContainer.classList.add('is-danger')
				
				fileInputContainer.parentElement.classList.add('has-border-danger')
				fileError.classList.remove('is-hidden')
	
				fileName.textContent = "No file uploaded";
			} else {
				fileName.textContent = fileInput.files[0].name;
				fileInputContainer.classList.remove('is-danger')
				fileInputContainer.classList.add('is-success')
	
				fileInputContainer.parentElement.classList.remove('has-border-danger')
				fileError.classList.add('is-hidden')
			}
		}
	});
}




var inputLists = document.querySelectorAll('[data-list="codes"]');
inputLists.forEach(el => {
    el.setAttribute('list', el.dataset.list);
})